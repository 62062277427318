export let fixFilterData = [
    {
      label: 'All',
      id: 'all',
    },
    {
      label: 'Announcement',
      id: 1,
    },
    {
      label: 'Ask Help',
      id: 3,
    },
    {
      label: 'Tracksheet',
      id: 14,
    },
    {
      label: 'Bookmarked Post',
      id: 0,
    }
  ];

export let allFilterData = [
    {
      label: 'All',
      id: 'all',
    },
    {
      label: 'Announcement',
      id: 1,
    },
    {
      label: 'Help / Referral',
      id: 3,
    },
    {
      label: 'Share Idea',
      id: 12,
    },
    {
      label: 'Thank You',
      id: 7,
    },
    {
      label: 'Business Referral',
      id: 20,
    },
    {
      label: 'Tracksheet',
      id: 14,
    },
    {
      label: 'Invite Visitor',
      id: 11,
    },
    {
      label: 'TYCA',
      id: 8,
    },
    {
      label: 'Group Meeting Report',
      id: 10,
    },
    // {
    //   label: 'Session Flow',
    //   id: 16,
    // },
    {
      label: 'TYCA Question',
      id: 17,
    },
    {
      label: 'Bookmarked Post',
      id: 0,
    },
    {
      label: 'Business Connect',
      id: 21,
    },
    {
      label: 'Business Generated',
      id: 22,
    },
    {
      label: 'MR Tracking',
      id: 23,
    }

  ];